import React from 'react';
import ContactUs from '../ContactUs/ContactUs'; // Adjust the path as needed
import './Footer.css'; // Import your Footer CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <ContactUs /> {/* Include the ContactUs component */}
    </footer>
  );
};

export default Footer;
