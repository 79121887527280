import React from 'react';

const ServicesDescription = ({ visible }) => {
  const styles = {
    servicesDescription: {
      padding: '20px',
      backgroundColor: '#f9f9f9', // Optional: Light background for contrast
      borderRadius: '8px', // Rounded corners
      textAlign: 'justify', // Change to 'center' for center alignment or 'right' for right alignment
    },
    titleContainer: {
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.5s',
    },
    title: {
      fontSize: '24px',
      color: '#003366', // Blue color for the title
    },
    contentContainer: {
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.5s',
    },
    content: {
      marginTop: '10px', // Space above the content
      color: '#000000', // Black color for the content
    },
  };

  return (
    <div style={styles.servicesDescription}>
      {/* Title in a separate div */}
      <div style={styles.titleContainer}>
        <h2 style={styles.title}>Welcome to Thiran360AI</h2>
      </div>

      {/* Content in a separate div */}
      <div style={styles.contentContainer}>
        <p style={styles.content}>
          We are dedicated to being the ultimate solution for your business needs.
        </p>
        <p style={styles.content}>
          Our mission is to automate your operations, allowing you to focus on strategic growth.
        </p>
        <p style={styles.content}>
          We understand the limitations businesses face, and we provide intelligent software solutions that help you overcome these challenges.
        </p>
      </div>
    </div>
  );
};

export default ServicesDescription;
