import React, { useRef } from 'react'

const cardData = [
  {
    name: 'Siddhu',
    companyname:'Siddhan Traders',
    image: 'c2.jpeg',
    cardContent: ['★★★★★', 'Highly professional and efficient, this team went above and beyond to ensure our website was perfect. From concept to execution, they demonstrated creativity, skill, and dedication, resulting in a flawless end product.'],
  },
  {
    name: 'Sathish Kumar',
    companyname:'Nedunchalai Cafe',
    image: 'c3.jpeg',
    cardContent: [
    
      '★★★★★',
      'We\'re thrilled with the mobile app they built! It’s fast, intuitive, and beautifully designed, giving our users a consistent experience on both Android and iOS platforms.,skill, and dedication, resulting in a flawless end product.'
    
    ],
  },
  {
    name: 'SATHEESH KUMAR S',
    image: 'sathistechlight.jpg',
    companyname:'Tech Light India',
    cardContent: [
      
      '★★★★★',
      'Working with this software company was a fantastic experience. They transformed our vision into a modern, responsive, and visually appealing website that has greatly enhanced our brand presence.'
    
    ],
  },
  
]

const Card = ({ image, cardContent, name, companyname }) => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: '#fff', 
      padding: '20px', 
      borderRadius: '10px', 
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
      marginTop: '-100px',
      width: '300px',  // Adjust width as needed
      margin: '10px',  // Space between cards
      textAlign: 'center', 
      ...styles.card
    }}>
      {/* Image */}
      <img src={image} alt={name} style={{ 
        width: '100%', 
        height: '200px', 
        objectFit: 'cover', 
        borderRadius: '10px' 
      }} />
      
      {/* Name */}
      <h3 style={{ 
        fontSize: '24px', 
        fontWeight: 'bold', 
        color: '#333', 
        marginTop: '15px'
      }}>
        {name}
      </h3>

      {/* Company Name */}
      <p style={{ 
        fontSize: '18px', 
        color: '#666', 
        fontStyle: 'italic', 
        margin: '8px 0'
      }}>
        {companyname}
      </p>

      {/* Card Content */}
      <ul style={{ 
        padding: '0', 
        listStyleType: 'none', 
        marginTop: '15px' 
      }}>
        {cardContent.map((point, index) => (
          <li key={index} style={{ 
            fontSize: '16px', 
            color: '#555', 
            marginBottom: '10px' 
          }}>
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};








const Carousel = () => {
  const contentRef = useRef(null)

  const handleNext = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: 300, behavior: 'smooth' })
    }
  }

  const handlePrev = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.carouselWrapper}>
        <div style={styles.carouselHeader}>
          {/* <div style={styles.carouselControls}> */}
            <button style={styles.carouselArrow} onClick={handlePrev}>
              &lt;
            </button>
            <button style={styles.carouselArrow} onClick={handleNext}>
              &gt;
            </button>
          {/* </div> */}
        </div>
        <ul style={styles.carouselContent} ref={contentRef}>
          {cardData.map((card, index) => (
            <li key={index} style={styles.carouselItem}>
              <Card
                image={card.image}
                cardContent={card.cardContent}
                name={card.name}
                companyname={card.companyname}  // Pass company name here
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 'auto',
    margin: '0 auto',
    padding: '20px',
  },
  carouselWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  carouselHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  carouselControls: {
    display: 'flex',
    gap: '14px',
  },
  carouselArrow: {
    padding: '10px',
    backgroundColor: 'white',
    color: 'black',
    border: 'solid black',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  carouselContent: {
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    padding: 0,
    margin: 0,
    gap: '20px',
    listStyle: 'none',
  },
  carouselItem: {
    flex: '0 0 auto',
    width: '300px',
    transition: 'transform 0.3s ease-in-out',
  },
  card: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '10px',
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
    marginTop: '10px',
  },
  cardContent: {
    listStyleType: 'none',
    padding: '0',
    margin: '10px 0 0',
  },
  cardText: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '5px',
  },
}

export default Carousel