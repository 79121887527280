import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Import Firestore specifically

// const firebaseConfig = {
//   apiKey: "YOUR_API_KEY",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   projectId: "YOUR_PROJECT_ID",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//   appId: "YOUR_APP_ID"
// };

const firebaseConfig = {
    apiKey: "AIzaSyA8T7TzUvh1Sd5trHv-nk0HjQ-IB6FRgyQ",
    authDomain: "thiran360ai-4f255.firebaseapp.com",
    projectId: "thiran360ai-4f255",
    storageBucket: "thiran360ai-4f255.firebasestorage.app",
    messagingSenderId: "158992820392",
    appId: "1:158992820392:web:a1ceb790853ff4bf787a9d",
    measurementId: "G-4T9YSGKYWY"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Export Firestore instance
export const db = getFirestore(firebaseApp);
export default firebaseApp;
