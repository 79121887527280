import React from 'react';
import ebenPaul from '../../assets/Eben Paul.jpg'; // Ensure this path is correct
import sathishKumar from '../../assets/Sathish Kumar.jpeg'; // Ensure this path is correct
import siddhu from '../../assets/siddhu.jpeg'; // Ensure this path is correct
import sathistechlight from '../../assets/sathistechlight.jpg'; // Ensure this path is correct

const Testimonials = () => {
    // Inline styles
    const styles = {
        testimonialsContainer: {
            padding: '20px',
            fontSize: '14px', // Slightly smaller overall font size
          
        },
        testimonialsTitle: {
            textAlign: 'left',
            marginBottom: '20px',
            fontSize: '24px', // Reduced title font size
            color: 'black' // Adjust color as needed
        },
        testimonialsCards: {
            display: 'flex', // Use flex layout for horizontal scrolling
            overflowX: 'auto', // Allow horizontal scrolling
            scrollbarWidth: 'none', // Hide scrollbar for Firefox
            '-ms-overflow-style': 'none', // Hide scrollbar for Internet Explorer and Edge
        },
        testimonialCard: {
            display: 'flex', // Use flex layout
            flexDirection: 'column', // Stack image and text vertically
            alignItems: 'center', // Center align items
            minWidth: '180px', // Minimum width for cards
            maxWidth: '240px', // Maximum width for cards
            margin: '10px', // Space between cards
            textAlign: 'center', // Center align text under images
            cursor: 'pointer', // Change cursor on hover
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Animation on hover
            height: 'auto' // Let height adjust automatically
        },
        testimonialImage: {
            width: '100%', // Full width of the card
            height: '180px', // Adjusted height for images
            objectFit: 'cover', // Ensure the image covers the assigned area without distortion
            marginBottom: '5px' // Space below the image
        },
        testimonialText: {
            fontWeight: 'bold', 
            fontSize: '14px', // Reduced font size
            margin: '5px 0 0 0', // Space above the text
            transition: 'color 0.3s ease' // Transition for text color
        },
        testimonialContent: {
            fontStyle: 'italic',
            color: '#555',
            fontSize: '13px',
            textAlign:'justify'
        },
        testimonialCardHover: {
            transform: 'scale(1.05)', // Scale effect on hover
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)' // Add shadow effect
        }
    };

    return (
        <div style={styles.testimonialsContainer}>
            <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>Testimonials</h2>
            <div style={styles.testimonialsCards}>
                
                <div style={styles.testimonialCard}>
                    <img src={sathishKumar} alt="Sathish Kumar" style={styles.testimonialImage} />
                    <p style={styles.testimonialText}>Sathish Kumar</p>
                    <p style={styles.testimonialText}>Nedunchalai Cafe</p>
                    <p style={styles.testimonialContent}>We're thrilled with the mobile app they built! It’s fast, intuitive, and beautifully designed, giving our users a consistent experience on both Android and iOS platforms.</p>
                </div>
                <div style={styles.testimonialCard}>
                    <img src={siddhu} alt="Siddhu" style={styles.testimonialImage} />
                    <p style={styles.testimonialText}>Siddhu</p>
                    <p style={styles.testimonialText}>Siddhan Traders</p>
                    <p style={styles.testimonialContent}>Highly professional and efficient, this team went above and beyond to ensure our website was perfect. From concept to execution, they demonstrated creativity, skill, and dedication, resulting in a flawless end product.</p>
                </div>
                <div style={styles.testimonialCard}>
                    <img src={sathistechlight} alt="Sathis" style={styles.testimonialImage} />
                    <p style={styles.testimonialText}>SATHEESH KUMAR S</p>
                    <p style={styles.testimonialText}>Tech Light India</p>
                    <p style={styles.testimonialContent}>Working with this software company was a fantastic experience. They transformed our vision into a modern, responsive, and visually appealing website that has greatly enhanced our brand presence.</p>
                </div>
                {/* Add more testimonial cards as needed */}
            </div>
        </div>
    );
};

export default Testimonials;
