import React from 'react';
import aiImage from '../../assets/ai.png';
import thiranImage from '../../assets/Thirann.png';
import logo360Image from '../../assets/360.jpg';
import busImage from '../../assets/bus.png';

const AboutUs = () => {
  const styles = {
    aboutUsContainer: {
      width: '100%',
      padding: '20px',
     
    },
    stickyContainer: {
      position: 'relative', 
      height: '60vh',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
    stickyCard: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#f9f9f9',
      padding: '10px',
      // minHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
      zIndex: 1,
    },
    aboutImage: {
      width: '120px',
      height: '120px',
      marginRight: '15px',
      borderRadius: '8px',
    },
    aboutContent: {
      color: '#333',
      textAlign: 'justify', // Justified text for about content
      flex: 1,
      lineHeight: '1.6', // Adjust line height for better readability
    },
    aboutContentHeading: {
      fontSize: '1.8rem',
      color: '#003366',
    },
    stableCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f9f9f9',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    stableImage: {
      width: '100px',
      height: '100px',
      marginBottom: '20px',
      borderRadius: '8px',
    },
    stableContent: {
      color: '#333',
      textAlign: 'justify', // Apply justification here for stable content
      lineHeight: '1.6', // Consistent line height
    },
    aboutUsHeading: {
      fontSize: '2em',
      marginBottom: '20px',
      textAlign: 'left',
      color: 'black',
      width: '100%',
    },
  };

  return (
    <div style={styles.aboutUsContainer}>
      <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>About Us</h2>

      <div style={styles.stickyContainer}>
        {/* First Card */}
        <div style={styles.stickyCard}>
          <img src={thiranImage} alt="Thiran" style={styles.aboutImage} />
          <div style={styles.aboutContent}>
            <h3 style={styles.aboutContentHeading}>About Thiran</h3>
            <p>The name 'Thiran' symbolizes talent and ability—qualities that define our team.</p>
          </div>
        </div>

        {/* Second Card */}
        <div style={styles.stickyCard}>
          <img src={logo360Image} alt="360" style={styles.aboutImage} />
          <div style={styles.aboutContent}>
            <h3 style={styles.aboutContentHeading}>360 Approach</h3>
            <p>The '360' in our name represents an all-encompassing approach to development.</p>
          </div>
        </div>

        {/* Third Card */}
        <div style={styles.stickyCard}>
          <img src={aiImage} alt="AI" style={styles.aboutImage} />
          <div style={styles.aboutContent}>
            <h3 style={styles.aboutContentHeading}>Artificial Intelligence</h3>
            <p>'AI' reflects our commitment to cutting-edge solutions that bring visions to life.</p>
          </div>
        </div>
      

      {/* Stable Card */}
      <div style={styles.stableCard}>
        <img src={busImage} alt="Bus" style={styles.stableImage} />
        <div style={styles.stableContent}>
          <h3 style={styles.aboutContentHeading}>At Thiran360AI</h3>
          <p>
            We are more than just a software company; we are your partner in success. 
            We specialize in automating businesses, solving complex challenges, 
            and providing solutions that drive growth.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AboutUs;
