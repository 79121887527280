import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Import LinkedIn icon

const ContactUs = () => {
  return (
    <div style={{ padding: '20px' }}>
     <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>Contact Us</h2>
      
      {/* Contact Information */}
      <div style={{ marginBottom: '20px', color: '#000000', fontSize: '18px', lineHeight: '1.8', textAlign: 'justify' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
          <p style={{ display: 'inline', marginTop:'13px' }}>admin@thiran360ai.com</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '8px', color: '#0077b5' }} />
          <a 
            href="https://www.linkedin.com/company/thiran360ai/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0077b5', textDecoration: 'none' }}
          >
            linkedin.com/company/thiran360ai
          </a>
        </div>
      </div>

      {/* Location Section */}
      <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>Our Locations</h2>
      <div style={{ display: 'flex', overflowX: 'auto', gap: '20px', padding: '20px 0', scrollSnapType: 'x mandatory', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
        <div style={{
          flex: '0 0 auto',
          minWidth: '250px',
          maxWidth: '50%',
          scrollSnapAlign: 'start',
        }}>
          <iframe
            title="THIRAN360AI Madiwala Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.4192075897915!2d77.61622707589125!3d12.921482188563301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150a7a1a78a7%3A0x4da49cc7f16e1466!2sThiran360AI!5e0!3m2!1sen!2sin!4v1696791761967!5m2!1sen!2sin"
            width="100%" // Ensure full width
            height="150"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <p>📌 Madiwala, Bengaluru - 560068</p>
        </div>
        <div style={{
          flex: '0 0 auto',
          minWidth: '250px',
          maxWidth: '50%',
          scrollSnapAlign: 'start',
        }}>
          <iframe
            title="THIRAN360AI Gobichettipalayam Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.262149806286!2d77.45464157575028!3d11.447362542730983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba93defffea8007%3A0x94d8ec21e155d18c!2sCFW3%2BWVR%2C%20Kullampalayam%2C%20Gobichettipalayam%2C%20Tamil%20Nadu%20638476!5e0!3m2!1sen!2sin!4v1696791859019!5m2!1sen!2sin"
            width="100%" // Ensure full width
            height="150"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <p>📌 Gobi, Erode - 638476</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
